.page-titles {
	background-color: $jc-grey;
	.breadcrumb {
		background: transparent;
		padding: 0px;
		float: left;
		margin-bottom: 0;
		li {
  		font-size: 11px;
  	}
}
}