/* Jobcenter Variablen */

$title-font: 'tablet-gothic', sans-serif;
$styled-font:'tablet-gothic', sans-serif;
$base-font:'tablet-gothic', sans-serif;
$primary-color:#2a3444;
$danger-color:#c9302c;
$success-color:#5cb85c;
$warning-color:#f0ad4e;
$default-color:#f7f7f7;
$dark-color:#141414;
$info-color:#5bc0de;
$jc-grey:#f2f2f2;
$jc-red: #e3000a;
$jc-green:#32ab69;
$jc-dark-grey:#525252;
$white:#ffffff;