/*
Image with text cards
*/

.image-text-card1{
    max-width:500px;
    margin:0 auto;
    position:relative;
    .card-link{
        width:100%;
        height:100%;
        position: absolute;
        display:block;
        left:0;
        top:0;
    }
    img.img-fluid{
        border-radius:8px;
        transition:all .3s;
        -webkit-transition:all .3s;
    }
    &:hover .img-fluid{
        transform:translateY(-4px);
        -webkit-transform:translateY(-4px);
        box-shadow: 0px 4px 30px rgba(0,0,0,0.1);
    }
}
.image-text-card2{
    .card-body{
        padding-top:0px;
        padding-bottom:0px;
    }
}

.image-card-title-overlay{
    position: relative;
    overflow:hidden;
    max-width:500px;
    margin:0 auto;
    img.img-fluid{
        border-radius:8px;
    }
    .card-link{
        width:100%;
        height:100%;
        position: absolute;
        display:block;
        left:0;
        top:0;
        z-index: 2;
    }
    .card-overlay{
        position:absolute;
        bottom:0px;
        left:0px;
        font-size:.8rem;
        margin:0px;
        border-radius: 8px;
        width:100%;
        height:100%;
        background-color:rgba($primary-color,.5);
        z-index: 1;
        color:#fff;
        .overlay-inner{
            transform:translateY(50%);
            -webkit-transform:translateY(50%);
            width:100%;
            height:100%;
            position:absolute;
            padding:10px;
            left:0;
            top:0;
            text-align:center;
            color:#fff;
            margin-top:-30px;
            h4{
                color:#fff;
                margin:0px;
            }
            p{
                font-size:12px;
                line-height:20px;
                font-style:italic;
            }
        }
    }
    &.card-hover{
        .card-overlay{
            opacity:0;
            visibility:hidden;
            transition:all .3s;
            -webkit-transition:all .3s;
            .overlay-inner{
                margin-top:0px;
                transition:all .3s;
                -webkit-transition:all .3s;
            }
        }  
        &:hover{
            .card-overlay{
                opacity:1;  
                visibility:visible;
                .overlay-inner{
                    margin-top:-30px;
                }
            }
        }
    }
}