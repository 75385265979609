/*
Footer styles
*/
.footer{
    &.footer-dark{
        background:  #212121;
        h3{
            color:#fff;
            margin-bottom:20px;
            font-size:1rem;
            font-weight:600;
            text-transform: uppercase;
        }
        hr{
            border-top-color:rgba(255,255,255,.2);
            border-bottom:1px solid rgba(0,0,0,.2);
        }
    }
    .social-icons{
        li{
            padding-right:20px;
            pa
            &:last-child{
                padding-right: 0;
            }
            a{
                color: rgba(255,255,255,0.6);
                font-size: 0.9rem;
                text-transform:capitalize;
                i{
                    position: relative;
                    border-right: 1px solid rgba(255, 255, 255, 0.6);
                    text-align: center;
                    padding-right: 10px;
                    margin-right: 10px;
                }
                &:hover{
                    color: rgba(255,255,255,1);
                }
            }
        }
    }
    h4{
        display: inline-block;
        padding-right: 20px;
        color: rgba(255,255,255,0.8);
        font-size:1rem;
        margin-bottom:30px;
        font-weight:300;
        letter-spacing:0;
        i{
            margin-right: 8px;
        }
    }
    p{
        color: rgba(255,255,255,0.6);
    }
}

.footer-list-item{
    li{
        padding-left:20px;
        padding-bottom:10px;
        position:relative;
        &:last-child{
            padding-bottom: 0px;
        }
        &:before{
            position: absolute;
            top:0;
            left:0;
               content: "\f105";
            font-family: 'FontAwesome';
        }
        a{
            color:rgba(#fff,.8);
            &:hover{
                color: #fff;
            }
        }
        em{
            font-size:12px;
             color: rgba(255,255,255,0.6);
        }
    }
}
.contact-list-item{
    li{
        padding-left: 40px;
        position:relative;
        margin-bottom:15px;
        color: rgba(255,255,255,0.6);
        min-height:32px;
         &:last-child{
            padding-bottom: 0px;
        }
        i{
            position: absolute;
            left:0;
            top:0;
            width:32px;
            height:32px;
            line-height: 32px;
            text-align: center;
            border-radius: 50%;
            border:1px solid rgba(255,255,255,.3);
            color: rgba(255,255,255,0.6);
            display: block;
        }
    }
}

.footer-light{
    &.footer{
        background-color: #f5f5f5;
        h3{
            font-size: 1rem;
            margin-bottom: 30px;
        }
        .footer-list-item li a{
            color:#333;
            &:hover{
                color: $primary-color;
            }
        }
        .footer-list-item li em{
            color: #999;
        }
        p{
            color: #666;
        }
        .contact-list-item li{
            color: #666;
            i{
                color: #fff;
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }
        .social-icons li a{
            color: #999;
            &:hover{
                color: $primary-color;
            }
            i{
                border-right:1px solid #eee;
            }
        }
        h4{
            color:#999;
        }
    }
}
.footer-standard{
    background-color: #222;
    font-size:14px;
    h3{
        color:#fff;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-size: 1rem;
    }

    .latest-news{
        img{
            border-radius: 5px;
            width: 70px;
        }
        .media-body{
            font-size: 12px;
        }
        h5{
            margin-bottom: 0px;
            font-size: 15px;
        }
        h5 a{
            color: rgba(255,255,255,.7);
        }
    }
}
.footer-bottomAlt{
    padding:15px 0;
    padding-top:20px;
    background-color:#000;
    span{
        display: block;
        text-align:right;
    }
}
/****sticky-footer**/
/**sticky footer*/
html.footer-sticky, .footer-sticky body{
  height: 100%;
}

@media (min-width: 992px) {
  .footer-sticky .page-footer-sticky {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100%;
  }
  .footer-sticky .page-footer-sticky .footer-sticky-wrap {
    -webkit-flex: 1 0 auto;
    -moz-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    min-height: 0;
    height: auto;
    position: relative;
    right: 0;
    min-height: 100%;
  }
  .footer-sticky .page-footer-sticky .footer-sticky-wrap .footer-sticky-inner {
    position: relative;
    width: 100%;
  }
}