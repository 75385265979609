body {
	background-color: #fafafa;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	color: $primary-color;
}

h1 {
	font-size: 30px;
}

h2 {
	font-size: 21px;
}

h3 {
	font-size: 18px;
}

h4 {
	font-size: 17px;
}

h5 {
	font-size: 12px;
}

h6 {
	font-size: 10px;
}

p {
	font-size: 15px;
}

.lead {
	color: $jc-dark-grey;
}

.small {
	font-size: 15px;
	text-transform: none;
}

.page-wrapper {
	.page-wrapper .container {
		width: 100%;
	}

	.intro {
		background: rgba(255, 255, 255, 0.7);
		text-transform: uppercase;
		font-weight: 400;

		h1 {
			font-weight: normal !important;
			font-size: 30px;
		}
	}

	ul {
		padding-left: 20px;
	}

	footer ul {
		padding-left: 0;
	}

	ul>li {
		font-size: 15px;
		color: $primary-color;
	}

	ol>li {
		font-size: 15px;
		color: $primary-color;
		line-height: 30px;
	}

	a:hover {
		color: red;
	}

	.card {
		border: none;
		background: rgba(255, 255, 255, 0.9);
	}

	.card-body {
		padding: 1.5rem;

		h3 {
			text-transform: uppercase;
			font-weight: normal;
		}

		i {
			margin-bottom: 20px;
		}

		.card-text {
			p {
				//margin-bottom: 0;
			}
		}
	}

	.start {
		.card {
			min-height: 255px;
		}

		.card-link {
			cursor: pointer;
		}
	}

	.stretched-link::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		pointer-events: auto;
		content: "";
		background-color: rgba(0, 0, 0, 0);
		cursor: pointer;
	}

	#accordion {
		.card-header.accordion-header {
			border-radius: calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px);
		}
	}
}

@media (min-width: 1200px) {
	.page-wrapper {
		max-width: 1280px;
		margin: 30px auto;
		padding: 0px;
		background-color: $white;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.54);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.54);
		box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.54);
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		.page-wrapper .container {
			width: 100%;
		}

		.image-card-title-overlay {
			max-width: 100%;
			min-height: 900px;
		}

		.intro {
			background: rgba(255, 255, 255, 0.7);
			text-transform: uppercase;
			font-weight: 400;
		}
	}
}