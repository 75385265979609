/*
Breadcrumb style
*/

.breadcrumb{
    background: transparent;
    padding: 0px;
    float:right;
    .breadcrumb-item{
        float: none;
        display: inline-block;
        padding-left: 5px;
    }
}
.page-title-center{
    .breadcrumb{
        display: inline-block;
        float:none;
        text-align:center;
    }
}