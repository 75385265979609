.page-wrapper {
	  .main {
	//background-image: url(/images/jobcenter_gap_bg_02.jpg);
	background-image: url(/images/unsplashsky_web.jpg);
	
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom center;
	  }
	

	  .ueber-uns, .team-cards, .textcards {
	  	.card {
	  		h3 {
	  			margin: 15px 0;
				  font-size: 20px;
				  font-weight: normal;
	  		}
	  	}
	  }
	  .card.spezial {
	  	min-height: 1px;
	  	h3 {
	  		color: #ee0200;
			  margin-bottom: 0;
			  font-weight: normal;
	  	}
	  }
  }
 
  .alert {
  	padding: 20px;
  	//background-color: rgba(93, 93, 93, 0.22);
  }