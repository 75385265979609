/*
Dropdown menu styles
*/
.dropdown-toggle::after{
    border-left:0px;
    border-right:0px;
    border-top:0px;
    font-family: "FontAwesome";
    content: "\f107";
    font-size: 14px;
    display: inline;
    margin-left: 7px;
    top: 5px;
    position: relative;
}
.dropdown-menu{
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
    min-width: 13rem;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    background-color: #fefefe;
    border:0px;
    &.lang-dropdown{
        width:100px;
        min-width:100px;
        >li>a{
            line-height: 18px!important;
        }
    }
}
.dropdown-menu .dropdown-item,.mega-inner-nav li a{
    font-weight:400;
    font-size: .8rem;
    color: rgba(#000,0.6);
    font-family: $base-font;
    position:relative;
    text-transform: capitalize;
    left:0;
    &:hover{
        left:5px;
    }
}
.dropdown .mega-inner-nav li a{
    padding-left: 0px;
    padding-right: 0px;
}
.mega-menu-content p{
    font-size: 12px;
    opacity: 0.6;
    color:#aeaeae;
}
/**mulit level dropdown**/
.dropdown-submenu { position: relative }
.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
    margin-left: -1px;
    border-radius: 0px;
}
.dropdown-submenu:hover>.dropdown-menu {
    display: block; 
}
.dropdown-submenu>a:after {
    display: block;
    float: right;
    font-family: "FontAwesome";
    content: "\f105";
    margin-top: 4px;
    margin-right: -5px;

}
.dropdown-submenu:hover>a:after { border-left-color: #ccc }
.dropdown-submenu.pull-left { float: none }
.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
// Mega menu canvas

.mega-title{
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color:#444;
}
.mega-inner-nav{
    li{
        vertical-align:middle;
        a{
            display: block;
            &:hover{
                color: $primary-color;
                background-color:transparent;
            }
            i{
                margin-right: 6px;
                position: relative;
                top: 1px;
                font-size: 13px;
                width: 16px;
                display: inline-block;
            }
        }
    }
}
.dropdown .dropdown-menu .dropdown-item,.mega-inner-nav>li>a{
    padding:4px 20px;
    &:hover{
        background-color:transparent;
        color: $primary-color;
    }
    &:focus{
        background-color:transparent;
        color: $primary-color;
    }
}
@media(min-width:992px){
    .dropdown-menu{       
        margin-top:0px;
        background-color: #212121;
        
    }
    .dropdown-menu .dropdown-item,.mega-inner-nav li a{
        color: rgba(#fff,.6);
    }
    .dropdown-menu.dropdown-mega-fw{
        position: absolute;
        width: 100%;
        padding:0px 20px;
        padding-bottom: 0px;
    }
    .mega-menu-content{
        padding: 30px 0;
        padding-bottom: 20px;
    }
    .dropdown-menu,.dropdown-submenu .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        -moz-transform: translateY(8px);
        -o-transform: translateY(8px);
        -ms-transform: translateY(8px);
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }
    .show>.dropdown-menu,.dropdown-submenu:hover>.dropdown-menu {
        opacity: 1;
        visibility: visible;
        z-index: 1000;
        -moz-transform: none;
        -o-transform: none;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
    }
    .mega-menu-content{
        .row .col-lg-3:first-child{
            border-left:0;
        }
        .row .col-lg-3{
            border-left:1px solid rgba(#fff,.09);
            padding:0px 30px;
        }
        .mega-title{
            color: #fff;
        }
    }
}