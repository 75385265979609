section.antraege, {
h3 {
	color: $jc-green;
	text-transform: none !important;
}

div.card-text {
	ul {
		padding-left: 0;
		overflow: hidden;
	li{
		list-style-type: none;
		line-height: 23px;
		padding-left: 25px;
		margin-bottom: 10px;
	}
	}
}
}