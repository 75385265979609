/*
Assan CLassic Template
Version- 3.8
author - design_mylife
support - mylifedesign143@gmail.com
*/

@font-face {
  font-family: 'FontAwesome';
  src: url("../bower_components/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("../bower_components/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format('embedded-opentype'),url("../bower_components/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0") format('woff2'),url("../bower_components/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0") format('woff'),url("../bower_components/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0") format('truetype'),url("../bower_components/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format('svg');
  font-weight: normal;
  font-style: normal;
}
/*themify icons*/
@font-face {
  font-family: 'themify';
  src: url("../bower_components/themify-icons/fonts/themify.eot");
  src: url("../bower_components/themify-icons/fonts/themify.eot?#iefix") format('embedded-opentype'),
		url("../bower_components/themify-icons/fonts/themify.woff") format('woff'),
		url("../bower_components/themify-icons/fonts/themify.ttf") format('truetype'),
		url("../bower_components/themify-icons/fonts/themify.svg") format('svg');
  font-weight: normal;
  font-style: normal;
}
/**et line icons**/
@font-face {
    font-family: 'et-line';
    src:url('../et-line-font/fonts/et-line.eot');
    src:url('../et-line-font/fonts/et-line.eot?#iefix') format('embedded-opentype'),
        url('../et-line-font/fonts/et-line.woff') format('woff'),
        url('../et-line-font/fonts/et-line.ttf') format('truetype'),
        url('../et-line-font/fonts/et-line.svg#et-line') format('svg');
    font-weight: normal;
    font-style: normal;
}

//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans:300,300i,400,400i,600,700,800');
//@import url('https://fonts.googleapis.com/css?family=Droid+Serif:400,400i');
@import"scss-classic-main/_variables.scss";
@import"scss-classic-main/_base.scss";
@import"scss-classic-main/_preloader.scss";
@import"scss-classic-main/_buttons.scss";
@import"scss-classic-main/_header.scss";
@import"scss-classic-main/_dropdown.scss";
//@import"scss-classic-main/_alerts-notifications.scss";
@import"scss-classic-main/_accordions.scss";
//@import"scss-classic-main/_accounts.scss";
//@import"scss-classic-main/_blockquotes.scss";
@import"scss-classic-main/_breadcrumb.scss";
//@import"scss-classic-main/_blog.scss";
//@import"scss-classic-main/_carousel.scss";
//@import"scss-classic-main/_clients.scss";
//@import"scss-classic-main/_counter-countdown.scss";
//@import"scss-classic-main/_hero-templates.scss";
@import"scss-classic-main/_icon-boxes.scss";
@import"scss-classic-main/_img-boxes.scss";
//@import"scss-classic-main/_modals.scss";
//@import"scss-classic-main/_page-titles.scss";
//@import"scss-classic-main/_paginations.scss";
//@import"scss-classic-main/_portfolio.scss";
//@import"scss-classic-main/_pricing-boxes.scss";
//@import"scss-classic-main/_progress-bar.scss";
//@import"scss-classic-main/_promo-boxes.scss";
//@import"scss-classic-main/_revolution-slider.scss";
//@import"scss-classic-main/_sidebar.scss";
//@import"scss-classic-main/_tabs.scss";
//@import"scss-classic-main/_team.scss";
//@import"scss-classic-main/_testimonials.scss";
@import"scss-classic-main/_title-headings.scss";
@import"scss-classic-main/_cards.scss";
@import"scss-classic-main/_top-bars.scss";
//@import"scss-classic-main/_career.scss";
//@import"scss-classic-main/_timeline.scss";
// @import"scss-classic-main/_smart-forms.scss";
//@import"scss-classic-main/_typed.js.scss";
@import"scss-classic-main/_image-text-card.scss";
@import"scss-classic-main/_footer.scss";
@import"scss-classic-main/_responsive-utilities.scss";
/**intro css (you can remove it)**/
//@import"scss-classic-main/_intro-assan.scss";
@import"scss-classic-main/_responsive.scss";
@import url("https://use.typekit.net/wcb5zsm.css");
@import"scss-jc/_jc-base.scss";
@import"scss-jc/_jc-header.scss";
@import"scss-jc/_jc-breadcrumb.scss";
@import"scss-jc/_jc-cards.scss";
@import"scss-jc/_jc-footer.scss";
//@import"scss-jc/_jc-teams_cards.scss";
//@import"scss-jc/_jc-kundenbuero.scss";
@import"scss-jc/_jc-antraege.scss";
@import"scss-jc/_jc-gap_custom.scss";

