/*
Cards style
*/
.card-inverse {
    .card-title{
    color:#fff;
}
p{
    color:#fff;
    opacity:.8;
}
}