/*
Preloader style
*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999; }

#preloader-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 5px;
  width: 5em;
  height: 5em;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  border-top: 0.5em solid $primary-color;
  border-right: 0.5em solid rgba(245, 245, 245, 0.5);
  border-bottom: 0.5em solid rgba(245, 245, 245, 0.5);
  border-left: 0.5em solid rgba(245, 245, 245, 0.5);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; }

#preloader-inner,
#preloader-inner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

