.footer-standard {
	background-color: $jc-grey;
	color: $primary-color;
	h3 {
		color: $jc-green;
		margin-bottom: 30px;
		text-transform: uppercase;
		font-size: 1.1em;
		font-weight: 400;
	}
	li a {
		color: $primary-color;
		&:hover {
			color: $jc-red;
		}
	}
}

.footer-list-item li {
	padding-left: 0;
	padding-bottom: 5px;
}

.footer-list-item li:before {
	display: none;
}

.footer-bottomAlt{
	background-color: $jc-dark-grey;
	span {
		text-align: left;
		color: $white;
		font-size: 11px;
	}
}