.top-bar{
	background-color: $jc-grey;
	padding: 5px 0 8px 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	.list-inline .list-inline-item a{
		font-size: 13px;
		text-transform: none;
	}
}

.navbar-brand img {
	width: 270px;
}

.navbar {
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
	.container {
		width: 100%;
	}
}

.navbar-nav {
	margin-right: 30px;
	.nav-item a{
		font-weight: normal;
		letter-spacing: 0;
		text-transform: none;
		font-size: 16px;
		color: $primary-color !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	li {
		border-left-style: solid;
		border-left-width: 1px;
		border-left-color: $jc-grey;
	}
	li:first-child {
		border-left: none;
	}
}

.navbar-right-elements {
	float: none !important;
	position: relative;
	top: 30px;
	right: 0;
	top: 0px;
	.ti-search {
		font-weight: bold;
		color: $primary-color;
	}
	.list-inline > li > a{
		top: 0;
	}
	.list-inline > li > a.menu-btn{
		top: -15px;
	}
}

.links .list-inline-item i {
color: #999;
margin-right: 5px;	
}

.pushy {
	background:  $white;
}

.pushy .cart-content .pushy-link {
	top: 30px;
	right: 30px;
}

.pushy .cart-content ul li {
	border-bottom: 1px solid $jc-grey;
	width: 100%;
}

.pushy a {
	color: $primary-color;
}

.pushy ul > li.clearfix{
	border: none;
}

.pushy .oHidden ul > li:before {
	content: "\25BA";
	position: relative;
	margin-left: 0px;
	font-family: 'Arial';
	font-size: 8px;
	color: red;
	padding-right: 15px;
	float: left;
}

.ti-close:before {
	color: $jc-dark-grey;
	font-size: 20px;
}

@media (max-width: 992px) {
.navbar-brand img {
	width: 210px;
}
.top-bar{
	background-color: $jc-grey;
	padding: 5px 0 8px 0;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}
.navbar-right-elements{
	top: 15px !important;
}
}

@media (min-width: 992px) {
.navbar {
	padding: 12px 0;
}
}

@media (min-width: 1200px) {
	.container{
		max-width: 1180px;
	}
}